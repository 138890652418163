
import { groupBy } from 'lodash';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { fieldDisplayName } from '@/util';
import { BiosampleSearchResult } from '@/data/api';
import DataObjectTable from './DataObjectTable.vue';

const buttonOrder = [
  'metagenome',
  'metatranscriptome',
  'proteomics',
  'metabolomics',
  'organic matter characterization',
];

export default defineComponent({
  components: {
    DataObjectTable,
  },

  props: {
    result: {
      type: Object as PropType<BiosampleSearchResult>,
      required: true,
    },
    expanded: {
      type: Object as PropType<{ resultId: string; omicsProcessingId: string; }>,
      required: true,
    },
    loggedInUser: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    function isOpen(omicsProcessingId: string) {
      return props.expanded.resultId === props.result.id
        && props.expanded.omicsProcessingId === omicsProcessingId;
    }

    const filteredOmicsProcessing = computed(() => Object.entries(groupBy(
      props.result.omics_processing,
      (p) => p.annotations.omics_type,
    )).sort(([agroup], [bgroup]) => {
      const ai = buttonOrder.indexOf(agroup.toLowerCase());
      const bi = buttonOrder.indexOf(bgroup.toLowerCase());
      return ai - bi;
    }));

    return {
      isOpen,
      filteredOmicsProcessing,
      fieldDisplayName,
    };
  },
});

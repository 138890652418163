var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('AppBanner'),_c('v-container',[(_vm.userLoading)?_c('div',[_vm._v(" Loading... ")]):(_vm.user)?_c('div',[_c('div',{staticClass:"mb-8"},[_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.user.name))]),(_vm.user.is_admin)?_c('v-chip',{staticClass:"mx-2",staticStyle:{"vertical-align":"baseline"},attrs:{"color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-shield-account ")]),_vm._v(" Admin ")],1):_vm._e()],1),_c('orcid-id',{attrs:{"orcid-id":_vm.user.orcid,"authenticated":false,"width":24}}),_c('v-row',[_c('v-col',{staticClass:"pt-6",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Email","dense":"","readonly":!_vm.editEmail,"filled":"","rules":_vm.requiredRules('E-mail is required',[
                  function (v) { return /.+@.+\..+/.test(v) || 'E-mail must be valid'; } ])},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.updateEmail(_vm.user.email)}}},[_c('v-icon',{domProps:{"textContent":_vm._s(!_vm.editEmail ? 'mdi-pencil' : 'mdi-content-save')}})],1)]},proxy:true},(!_vm.user.email)?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":"","color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,false,3764470461)},[_c('span',[_vm._v("Email is required")])])]},proxy:true}:null],null,true),model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1)],1),_c('div',{staticClass:"mb-8"},[_c('div',{staticClass:"text-h5 py-2"},[_vm._v(" Developer Tools ")]),_c('p',[_vm._v(" To access authenticated endpoints in the "),_c('a',{attrs:{"href":"/api/docs"}},[_vm._v("NMDC Data and Submission Portal API")]),_vm._v(" you must provide an "),_c('b',[_vm._v("Access Token")]),_vm._v(" in the "),_c('code',[_vm._v("Authorization")]),_vm._v(" header of the request. An "),_c('b',[_vm._v("Access Token")]),_vm._v(" is valid for 24 hours and can be obtained by providing your "),_c('b',[_vm._v("Refresh Token")]),_vm._v(" to the "),_c('code',[_vm._v("/auth/refresh")]),_vm._v(" endpoint. Your "),_c('b',[_vm._v("Refresh Token")]),_vm._v(" and its expiration date can be found below. "),_c('b',[_vm._v("Treat this Refresh Token as securely as you would treat a password!")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{ref:"refreshTokenInput",attrs:{"label":"Refresh Token","readonly":"","filled":"","type":_vm.isTokenVisible ? 'text' : 'password',"value":_vm.refreshToken},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""},on:{"click":_vm.handleRefreshTokenVisibilityButtonClick}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.isTokenVisible ? 'mdi-eye' : 'mdi-eye-off')+" ")])]}}])},[(_vm.isTokenVisible)?_c('span',[_vm._v("Hide Token")]):_c('span',[_vm._v("Show Token")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""},on:{"click":_vm.handleRefreshTokenCopyButtonClick}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}])},[_c('span',[_vm._v("Copy Token")])])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"label":"Expiration Date","readonly":"","filled":"","type":"text","value":_vm.refreshTokenExpirationDate}})],1)],1),_c('div',{staticClass:"text-h6 py-2"},[_vm._v(" Example ")]),_c('ol',{staticClass:"mb-4"},[_c('li',[_vm._v(" In your project, store your Refresh Token in a variable named "),_c('code',[_vm._v("REFRESH_TOKEN")]),_vm._v(". ")]),_c('li',[_vm._v(" Exchange your Refresh Token for an Access Token which will be valid for 24 hours. "),_c('pre',{staticClass:"grey lighten-4 my-2 pa-2"},[_vm._v("curl \\\n  -H \"content-type: application/json\" \\\n  -d \"{ \\\"refresh_token\\\": \\\"$REFRESH_TOKEN\\\"}\" \\\n  "+_vm._s(_vm.origin)+"/auth/refresh")])]),_c('li',[_vm._v(" Store the value returned in the "),_c('code',[_vm._v("access_token")]),_vm._v(" in your program and use it when making authenticated API requests. "),_c('pre',{staticClass:"grey lighten-4 my-2 pa-2"},[_vm._v("curl \\\n  -H \"content-type: application/json\" \\\n  -H \"Authorization: Bearer $ACCESS_TOKEN\" \\\n  "+_vm._s(_vm.origin)+"/api/me")])]),_c('li',[_vm._v(" If your program runs for more than 24 hours. Step 2 will need to be repeated once the Access Token expires. ")]),_c('li',[_vm._v(" When your Refresh Token expires (one year after your last login), visit this page again to get a new one. ")])])],1)]):_c('div',[_vm._v(" You must log in to view this page. ")]),_c('v-snackbar',{attrs:{"timeout":"3000"},model:{value:(_vm.isCopyRefreshTokenSnackbarVisible),callback:function ($$v) {_vm.isCopyRefreshTokenSnackbarVisible=$$v},expression:"isCopyRefreshTokenSnackbarVisible"}},[_vm._v(" Refresh Token Copied to Clipboard ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
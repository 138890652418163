
import {
  computed, defineComponent, ref, watch,
} from '@vue/composition-api';
// @ts-ignore
import NmdcSchema from 'nmdc-schema/nmdc_schema/nmdc_materialized_patterns.yaml';

import { geneFunctionTables, types } from '@/encoding';
import {
  api, Condition, DatabaseSummaryResponse, entityType,
} from '@/data/api';

import ConditionChips from '@/components/Presentation/ConditionChips.vue';

import MenuContent from '@/components/MenuContent.vue';
import FacetedSearch, { SearchFacet } from '@/components/FacetedSearch.vue';

import {
  stateRefs, removeConditions, setConditions, toggleConditions,
} from '@/store';

/**
 * Sidebar has a fixed list of facets, possibly from different tables.
 */
const FunctionSearchFacets: SearchFacet[] = [
  {
    field: 'id',
    table: 'kegg_function',
  },
  {
    field: 'id',
    table: 'cog_function',
  },
  {
    field: 'id',
    table: 'pfam_function',
  },
  {
    field: 'id',
    table: 'go_function',
  },
  /** MIxS Environmental Triad */
  {
    field: 'env_broad_scale',
    table: 'biosample',
  },
  {
    field: 'env_local_scale',
    table: 'biosample',
  },
  {
    field: 'env_medium',
    table: 'biosample',
  },
  /** GOLD */
  {
    field: 'gold_classification',
    table: 'biosample',
  },
  /** Biosample */
  {
    field: 'geo_loc_name',
    table: 'biosample',
    group: 'Sample',
  },
  {
    field: 'depth',
    table: 'biosample',
    group: 'Sample',
  },
  {
    field: 'latitude',
    table: 'biosample',
    group: 'Sample',
  },
  {
    field: 'longitude',
    table: 'biosample',
    group: 'Sample',
  },
  {
    field: 'collection_date',
    table: 'biosample',
    group: 'Sample',
  },
  /** Study */
  {
    field: 'principal_investigator_name',
    table: 'study',
    group: 'Study',
  },
  /** Data Generation */
  {
    field: 'instrument_name',
    table: 'omics_processing',
    group: 'Data Generation',
  },
  {
    field: 'omics_type',
    table: 'omics_processing',
    group: 'Data Generation',
  },
  {
    field: 'processing_institution',
    table: 'omics_processing',
    group: 'Data Generation',
  },
];

export default defineComponent({
  components: {
    ConditionChips,
    FacetedSearch,
    MenuContent,
  },

  props: {
    resultsCount: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const filterText = ref('');
    const textSearchResults = ref([] as Condition[]);
    const dbSummary = ref({} as DatabaseSummaryResponse);
    const biosampleDescription = computed(() => {
      const { schemaName } = types.biosample;
      if (schemaName !== undefined) {
        // @ts-ignore
        const schema = NmdcSchema.classes[schemaName];
        return schema.description || '';
      }
      return '';
    });

    api.getDatabaseSummary().then((s) => { dbSummary.value = s; });

    function dbSummaryForTable(table: entityType, field: string) {
      if (table in dbSummary.value) {
        return dbSummary.value[table].attributes[field];
      }
      if (geneFunctionTables.includes(table)) {
        const tableToType: Record<string, string> = {
          kegg_function: 'kegg_function',
          cog_function: 'cog_function',
          pfam_function: 'pfam_function',
          go_function: 'go_function',
        };
        return {
          type: tableToType[table],
        };
      }
      return {};
    }

    async function updateSearch() {
      if (filterText.value.length >= 2) {
        textSearchResults.value = await api.textSearch(filterText.value);
      } else {
        textSearchResults.value = [];
      }
    }
    watch(filterText, updateSearch);

    return {
      /* data */
      biosampleDescription,
      conditions: stateRefs.conditions,
      dbSummary,
      textSearchResults,
      filterText,
      FunctionSearchFacets,
      types,
      /* methods */
      dbSummaryForTable,
      removeConditions,
      setConditions,
      toggleConditions,
    };
  },
});


import {
  defineComponent, PropType, onBeforeUnmount, computed,
} from '@vue/composition-api';
// @ts-ignore
import NmdcSchema from 'nmdc-schema/nmdc_schema/nmdc_materialized_patterns.yaml';

import { fieldDisplayName } from '@/util';
import { getField, geneFunctionTypeInfo, geneFunctionTables } from '@/encoding';
import FacetSummaryWrapper from '@/components/Wrappers/FacetSummaryWrapper.vue';
import FilterDate from '@/components/Presentation/FilterDate.vue';
import FilterFloat from '@/components/Presentation/FilterFloat.vue';
import FilterList from '@/components/Presentation/FilterList.vue';
import FilterSankeyTree from '@/components/FilterSankeyTree.vue';
import FilterGene from '@/components/FilterGene.vue';
import FilterTree from '@/components/FilterTree.vue';
import { urlify } from '@/data/utils';
import { AttributeSummary, Condition, entityType } from '@/data/api';

export default defineComponent({
  components: {
    FacetSummaryWrapper,
    FilterDate,
    FilterFloat,
    FilterList,
    FilterGene,
    FilterSankeyTree,
    FilterTree,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    table: {
      type: String as PropType<entityType>,
      required: true,
    },
    summary: {
      type: Object as PropType<AttributeSummary>,
      required: true,
    },
    conditions: {
      type: Array as PropType<Condition[]>,
      required: true,
    },
    update: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    onBeforeUnmount(() => emit('close'));

    const description = computed(() => {
      const fieldSchemaName = getField(props.field, props.table);
      const schemaName = fieldSchemaName.schemaName || props.field;
      if (schemaName !== undefined) {
        const schema = NmdcSchema.classes[schemaName] || NmdcSchema.slots[schemaName];
        // Avoid restating the field name as a description
        if (schema && schema.description !== schemaName) {
          return schema?.description || '';
        }
      }
      return '';
    });

    return {
      description,
      fieldDisplayName,
      getField,
      urlify,
      geneFunctionTypeInfo,
      geneFunctionTables,
    };
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm.fieldDisplayName(_vm.field, _vm.table))+" "),(_vm.summary.units)?_c('span',{staticClass:"pl-2"},[_vm._v(" ("+_vm._s(_vm.summary.units.name)+") ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.description)?_c('v-card-text',{staticClass:"py-1 text-caption",domProps:{"innerHTML":_vm._s(_vm.urlify(_vm.description))}}):_vm._e(),(_vm.isOpen)?[(_vm.summary.type === 'string')?_c('filter-list',{attrs:{"field":_vm.field,"table":_vm.table,"conditions":_vm.conditions},on:{"select":function($event){return _vm.$emit('select', $event)}}}):_vm._e(),(_vm.geneFunctionTables.includes(_vm.summary.type))?_c('FilterGene',{attrs:{"field":_vm.field,"table":_vm.table,"conditions":_vm.conditions,"gene-type-params":_vm.geneFunctionTypeInfo[_vm.summary.type.split('_')[0]],"gene-type":_vm.summary.type.split('_')[0]},on:{"select":function($event){return _vm.$emit('select', $event)}}}):_vm._e(),(_vm.summary.type === 'date')?_c('filter-date',_vm._b({staticClass:"pa-5",on:{"select":function($event){return _vm.$emit('select', $event)}}},'filter-date',{
        field: _vm.field, type: _vm.table, conditions: _vm.conditions,
        min: _vm.summary.min,
        max: _vm.summary.max,
        update: _vm.update,
      },false)):(['float', 'integer'].includes(_vm.summary.type))?_c('filter-float',_vm._b({staticClass:"pa-5",on:{"select":function($event){return _vm.$emit('select', $event)}}},'filter-float',{
        field: _vm.field, type: _vm.table, conditions: _vm.conditions,
        min: _vm.summary.min,
        max: _vm.summary.max,
        update: _vm.update,
      },false)):(['sankey-tree'].includes(_vm.summary.type))?_c('filter-sankey-tree',_vm._b({on:{"select":function($event){return _vm.$emit('select', $event)}}},'filter-sankey-tree',{ field: _vm.field, table: _vm.table, conditions: _vm.conditions },false)):(['tree'].includes(_vm.summary.type))?_c('facet-summary-wrapper',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('filter-tree',_vm._b({on:{"select":function($event){return _vm.$emit('select', $event)}}},'filter-tree',props,false))]}}],null,false,1622599516)},'facet-summary-wrapper',{ table: _vm.table, field: _vm.field, conditions: _vm.conditions },false)):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }